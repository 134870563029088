<template>
  <div class="contentWrap">
    <!-- dialog -->
    <!-- dialog -->
    <!-- top -->
    <!-- top -->
    <div style="margin-bottom: 15px"></div>

    <van-sticky style="width: 100vw">
      <van-nav-bar title="出貨金额统计">
        <template #right>
          <div class="rightSelect" @click="open = true">
            <van-icon
              style="width: 20px"
              name="search"
              @click="$router.push('/orderCRecord')"
            />
          </div>
        </template>
      </van-nav-bar>
    </van-sticky>

    <van-cell-group inset style="margin: 15px 0 15px 0; width: 100%" class="">
      <div class="tWrap">
        <div class="wrapContent">
          <div class="bottom" v-if="stats.bonus">
            <div class="tContent">
              <div class="tContentWrap">
                <p class="chF">今日总红利(元)</p>
                <p class="numF">
                  {{ stats.bonus_stats[0].today | thousands }}
                </p>
              </div>
              <div class="tContentWrap">
                <p class="chF">昨日总红利(元)</p>
                <p class="numF">
                  {{ stats.bonus_stats[0].yesterday | thousands }}
                </p>
              </div>
            </div>
            <div class="tContent">
              <div class="tContentWrap">
                <p class="chF">今日完成笔数(单)</p>
                <p class="numF">{{ stats.payment.count | thousands }}</p>
              </div>
              <div class="tContentWrap">
                <p class="chF">今日完成金额(元)</p>
                <p class="numF">{{ stats.payment.amount | thousands }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-cell-group>
    <div style="width: 335px">
      <div class="tSelectWrap">
        <div class="tSelect">
          <span @click="getMethod = '0'" :class="{ active: getMethod == '0' }"
            >待付款</span
          >
          <span @click="getMethod = '1'" :class="{ active: getMethod == '1' }"
            >成功</span
          >
          <span @click="getMethod = '2'" :class="{ active: getMethod == '2' }"
            >逾时失效
          </span>
          <span @click="getMethod = '3'" :class="{ active: getMethod == '3' }"
            >金额错误</span
          >
          <span @click="getMethod = '4'" :class="{ active: getMethod == '4' }"
            >实名不符</span
          >
          <span @click="getMethod = '5'" :class="{ active: getMethod == '5' }"
            >失败</span
          >
        </div>
        <!-- <van-icon
          name="search"
          class="searchIcon"
          @click="$router.push('/orderCRecord')"
        /> -->
      </div>
      <div class="cardWrap" v-for="order in orders.data" :key="order.order_id">
        <div class="left">
          <img
            v-if="
              order.content &&
              Array.isArray(order.content) &&
              order.content.length
            "
            :src="order.content[0].img"
            alt=""
          />
          <div class="orderContent">
            <p v-if="order.bank_name == '抖音红包'" style="font-size: 14px">
              口令码: {{ order.bank_branch }}
            </p>
            <div class="amountWrap">
              <div>
                <p>金额</p>
                <p class="amount">
                  ¥ {{ String(order.amount).split(".00")[0] }}
                </p>
              </div>
            </div>
            <p
              class="boder-top"
              v-if="
                order.content &&
                Array.isArray(order.content) &&
                order.content.length
              "
              style="overflow: hidden; padding-top: 5px; color: #646566"
            >
              {{ order.content[0].name }}
              <span
                style="display: none"
                v-if="
                  order.content &&
                  Array.isArray(order.content) &&
                  order.content.length
                "
              >
                {{ (order.name = order.content[0].name) }}
                {{ (order.img = order.content[0].img) }}
                {{ (order.price = order.content[0].price) }}
              </span>
              <span v-else>
                {{ order.content }}
              </span>
            </p>
          </div>
        </div>
        <div class="bottom" @click="checkOrder(order)">
          <div class="bottomBtn">
            <p>查看详细内容</p>
          </div>
        </div>
      </div>
    </div>
    <foor />
  </div>
</template>
<script>
import { mapState } from "vuex";
import foor from "../../components/footer";
export default {
  components: {
    foor,
  },
  data() {
    return {
      setOrderModal: false,
      orderForm: {},
      currentOrder: {},
      getMethod: "0",
      stats: {
        order: {},
      },
    };
  },
  computed: {
    ...mapState(["orders", "pOrders", "isReLoadding", "except"]),
  },
  watch: {
    getMethod() {
      this.$store.dispatch("getOrder", { page: 1, status: this.getMethod });
    },
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    returnTagType(status) {
      if (status == 0) return "warning";
      if (status == 1) return "success";
      return "danger";
    },
    getStats() {
      this.$http.get(`/mobile/stats`).then((res) => {
        if (res.data.code == 0) {
          this.stats = res.data.data;
        }
      });
    },
    checkOrder(order) {
      this.$router.push({
        name: "orderCInfo",
        params: order,
        query: order,
      });
    },
    checkPOrder() {
      this.$router.push(`/orderPInfo`);
    },
    scrollPage() {
      let params = {};
      if (window.scrollY + window.screen.height >= document.body.scrollHeight) {
        if (this.orders.next_page) {
          params.status = this.getMethod;
          params.page = this.orders.current_page * 1 + 1;
          this.$store.dispatch("getOrder", params);
        }
      }
    },
  },
  created() {
    if (this.$route.query.getMethod) {
      this.getMethod = this.$route.query.getMethod;
    }
    this.getStats();
    this.$store.dispatch("getOrder", { page: 1, status: this.getMethod });
  },
  mounted() {
    window.addEventListener("scroll", this.scrollPage);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollPage);
  },
};
</script>
<style lang="scss" scoped>
.numF {
  font-family: "Montserrat" !important;
}
.cardFooter {
  display: flex;
  button {
    width: 50%;
  }
}
.dialogInfo {
  padding: 0 5px;
  color: rgb(219, 99, 101);
  font-size: 14px;
}
.selectBox {
  width: 80px;
  height: 80px;
  background-color: #fff;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #9d9b9b;
  font-size: 10px;
  // padding: 20px;
}
.selectBox.active {
  border: 1px solid black;
  color: black;
}
.tWrap {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 20px;
  flex-direction: column;
  padding-bottom: 10px;
  .title {
    font-size: 11pt;
  }
  // flex-wrap: wrap;
  .wrapContent {
    display: flex;
    flex-direction: column;
  }
  .top {
    display: flex;
    width: 100%;
    padding: 5px;
  }
  .wrapTitle {
    font-weight: 500;
    font-size: 18px;
    width: 50%;
    text-align: center;
    &:first-child {
      border-right: 1px solid rgba(#cccccc, 0.3);
    }
    &:last-child {
      // border-left: 0.5px solid rgba(#cccccc, 0.3);
    }
    p:first-child {
      font-size: 14pt;
    }
    p.numF {
      font-size: 18px;
      margin-top: 5px;
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .tContent {
      width: 50%;
      &:first-child {
        .chF {
          border-left: 4px solid rgba(64, 101, 240, 1);
          padding-left: 5px;
        }
      }
      &:last-child {
        .chF {
          border-left: 4px solid rgba(63, 187, 254, 1);
          padding-left: 5px;
        }
      }
      .tContentWrap {
        display: flex;
        flex-direction: column;
        text-align: left;
        .chF {
          font-size: 12px;
          padding-top: 1.5px;
        }
        .numF {
          margin-top: 5px;
          margin-bottom: 10px;
          margin-left: 10px;
          font-weight: bold;
        }
        p {
          &:first-child {
            color: #9d9b9b;
          }
        }
        .numF {
          font-size: 16px;
        }
      }
    }
  }
}
.tSelectWrap {
  width: 100%;
  height: 35px;
  border-radius: 20px;
  // background-color: #fff;
  margin-bottom: 15px;
  // padding-left: 15px;
  display: flex;
  align-items: center;
  .tSelect {
    overflow-x: scroll;
    display: flex;
    max-width: 100%;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
  span {
    font-size: 14px;
    // line-height: 35px;
    margin-right: 10px;
    white-space: nowrap;
    color: black;
    background-color: white;
    border-radius: 5px;
    padding: 8px 10px;
    color: rgba(184, 182, 183, 1);
    &.active {
      color: white;
      background-color: rgba(63, 187, 254, 1);
      font-weight: bold;
    }
  }
  i {
    margin-left: auto;
    margin-right: 15px;
    font-size: 20px;
  }
}
.contentWrap {
  padding: 20px;
  display: flex;
  padding-bottom: 100px;
  padding-top: 0;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #f1f5fe;
}
.contentWrap {
  // background-image: url("/home-page_bg.png");
  background-repeat: no-repeat;
  min-height: 100vh;
  background-position: top center;
  width: 100vw;
  background-size: cover;
  background-attachment: fixed;
}
.cardWrap {
  background-color: #fff;
  border-radius: 5px;
  min-height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  // .left {
  //   display: flex;
  //   align-items: center;
  //   p {
  //     width: 120px;
  //     font-size: 12px;
  //     font-weight: bold;
  //     &:last-child {
  //       color: #cccccc;
  //     }
  //   }
  //   img {
  //     width: 28px;
  //     height: 28px;
  //     margin-left: 15px;
  //     margin-right: 15px;
  //   }
  // }

  .left {
    display: flex;
    width: 100%;
    // align-items: center;
    position: relative;
    p {
      width: 120px;
      font-size: 14px;
      font-weight: bold;
      &.boder-top {
        // color: #cccccc;
        border-top: 1px solid rgba(220, 236, 225, 1);
        width: 100%;
      }
    }
    img {
      width: 80px;
      height: 80px;
      // margin-left: 15px;
      margin-right: 15px;
    }
  }
  .bottom {
    width: 100%;
    margin-top: 15px;
    // border-top: 1px solid rgba(gray, 0.3);
    .bottomBtn {
      text-align: center;
      background-color: rgba(241, 245, 254, 1);
      padding: 10px 0;
      color: rgba(64, 101, 240, 1);
      border-radius: 6px;
    }
  }
  .right {
    .orderContent {
      display: flex;
      font-weight: bold;
      p {
        margin-right: 15px;
      }
      button {
        margin-right: 10px;
        font-size: 12px;
      }
    }
  }
}
.cardWrap {
  background-color: #fff;
  border-radius: 15px;
  // height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  padding: 15px;
  flex-direction: column;
  // padding-bottom: 15px;
  font-weight: bold;
  .amount {
    color: rgba(64, 101, 240, 1);
    font-size: 14px;
  }
  .orderContent {
    width: 100%;
  }
  .amountWrap {
    display: flex;
    // flex-direction: column
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
}
.marginTop {
  margin-top: 8px;
}

.tag {
  background-color: rgb(255, 244, 228);
  padding: 2px 8px;
  font-weight: bold;
  color: rgb(251, 199, 42);
  border-radius: 100px;
  font-size: 12px;
  position: absolute;
  right: 5px;
  top: 0px;
}

.balanceWrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
  .left,
  .right {
    width: 48%;
    background-color: white;
    border-radius: 15px;
    display: flex;
    align-items: center;
    color: white;
    height: 65px;
    padding-left: 15px;
    font-family: Noto Sans SC;
    .numF {
      font-weight: 700;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
  .left {
    background-color: #4065f0;
    .img {
      background-color: rgba(255, 255, 255, 0.2);
      width: 30px;
      height: 30px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      img {
        width: 20px;
      }
    }
  }
  .right {
    background-color: #3fbbfe;
    .img {
      background-color: rgba(255, 255, 255, 0.2);
      width: 30px;
      height: 30px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      img {
        width: 20px;
      }
    }
  }
}
.rightSelect {
  font-size: 20px;
}
</style>
